import { ReactNode } from 'react';

export enum MenuItemKey {
  ORGANIZATION = 'ORGANIZATION',
  EMPLOYEES = 'EMPLOYEES',
  CALENDAR = 'CALENDAR',
  CALENDAR_SCHEDULE_EMPLOYEES = 'CALENDAR_SCHEDULE_EMPLOYEES',
  SERVICES = 'SERVICES',
  REPORTS = 'REPORTS',
  // company level
  THEMING = 'THEMING',
  ORGANIZATIONS = 'ORGANIZATIONS',
  COMPANY_HOME_PAGE = 'COMPANY_HOME_PAGE',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  VISITS = 'VISITS',
  CLIENTS = 'CLIENTS',
  FEEDBACKS = 'FEEDBACKS',
}

export type MENU_ITEM = {
  link: string;
  label: string;
  isActive?: boolean;
  isHidden?: boolean;
  icon?: ReactNode;
}
export type MENU_ITEM_MAP = {
  [key in MenuItemKey]?: MENU_ITEM;
};
