import { useCallback, useEffect, useRef, useState } from 'react';

import { apiGetEmployeesByOrganizationId } from 'api/employees';
import { AutocompleteOptionType } from 'components/Selects/types';
import { SpecialistDTO } from 'components/types';

const useOrganizationEmployees = ({organizationId, companyId, selectedUserId: selectedUserIdArgs}: {organizationId: number, companyId: number, selectedUserId?: number}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employees, setEmployees] = useState<SpecialistDTO[]>([]);
  const [employeesOptions, setEmployeesOptions] = useState<AutocompleteOptionType<SpecialistDTO, number>[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<
    SpecialistDTO['id']
    >();
  const organizationIdState = useRef<number>();

  const reloadEmployees = useCallback(() => {
    if(!organizationId || !companyId) return;

    setSelectedEmployee(undefined);
    setIsLoading(true)

    apiGetEmployeesByOrganizationId(
      {organizationId, companyId}
    )
      .then(result => {
        const options: AutocompleteOptionType<SpecialistDTO, number>[]  = result.data.map((i) => ({ label: i.name, value: i.id, ...i }));

        setEmployees(result.data);
        setEmployeesOptions(options)
        if(selectedUserIdArgs) {
          setSelectedEmployee(options?.find(i => i.userId === selectedUserIdArgs)?.value);
        } else {
          setSelectedEmployee(options?.[0]?.value);
        }
      })
      .finally(() => {
        organizationIdState.current = organizationId;
        // setDataOrigin({organizationId});
        setIsLoading(false)
      })
  }, [organizationId]);

  useEffect(() => {
    reloadEmployees();
  }, [organizationId])

  return {
    employees,
    setEmployees,
    reloadEmployees,
    selectedEmployee,
    setSelectedEmployee,
    employeesOptions,
    isLoading: isLoading || organizationIdState.current !== organizationId,
  }
}

export default useOrganizationEmployees;
