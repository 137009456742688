import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { apiEditOrganization, getOrganizationsByCompanyIdApi } from 'api/organizations';
import showNotification from 'components/Notifications/showNotification';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import SpinnerPage from 'components/SpinnerPage/SpinnerPage';
import { OrganizationType, UserDTO, UserRoleType } from 'components/types';
import { useCompanies } from 'providers/CompaniesProvider';

type OrganizationContextType = {
  organization: OrganizationType | null;
  organizationId: OrganizationType['id'];
  organizations: OrganizationType[];
  organizationsOptions: { label: string; value: number | string }[];
  organizationUerRoles: UserRoleType[];
  organizationEmployeeId: number;
  setOrganization: (value: OrganizationType) => void;
  isLoading: boolean;
  loadOrganizations: (reloadUserData?: boolean) => void;
  updateOrganization: ({organizationId, organization, callback }: {organizationId: number, organization: OrganizationType, callback?: () => void }) => Promise<void>;
  timezone: string;
};
const OrganizationContextInitialState = {
  organization: null,
  organizationId: 0,
  organizations: [],
  organizationsOptions: [],
  organizationUerRoles: [],
  setOrganization: _.noop,
  isLoading: false,
  loadOrganizations: _.noop,
  updateOrganization: () => Promise.resolve(),
  organizationEmployeeId: 0,
  timezone: 'Europe/Kyiv',
};

const OrganizationContext = React.createContext<OrganizationContextType>(
  OrganizationContextInitialState
);

const OrganizationProvider: FC = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {user, loadUserData} = useAuth()
  const {company} = useCompanies();
  const [organization, setOrganization] = useState<OrganizationType | null>(
    null
  );
  const [organizations, setOrganizations] = useState<OrganizationType[]>([]);
  const [organizationsOptions, setOrganizationsOptions] = useState<
    { label: string; value: number | string }[]
  >([]);
  const [organizationUerRoles, setOrganizationUerRoles] = useState<UserRoleType[]>([]);
  const [organizationsDB, setOrganizationsDB] = useState<OrganizationType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getOrganizationFromUrl = () => {
    const locationArray = location.pathname.split('/');
    const organizationIndex = locationArray.indexOf('organization');
    const orgId = locationArray?.[`${organizationIndex + 1}`];

    return orgId ? Number(orgId) : orgId;
  };

  const forceRequest = (reloadUserData?: boolean) => {
    if(company) {
      setIsLoading(true)
      getOrganizationsByCompanyIdApi({ companyId: company.id })
        .then(result => {
          setOrganizationsDB(result.data)
        })
        .finally(() => {
          if(reloadUserData) {
            loadUserData(true);
          }
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {

    if (organizationsDB) {
      const urlOrganizationId = getOrganizationFromUrl();

      const newOrganization = organizationsDB.find(org => org.id === urlOrganizationId) || organizationsDB[0];

      //spike for currency before BE will add it to organization
      newOrganization.currency = newOrganization.currency || 'UAH';

      setOrganization(newOrganization);
      setOrganizations(organizationsDB);
      setOrganizationsOptions(
        organizationsDB.map((item) => ({
          value: item.id,
          label: `${item.name} ${item.address ? `(${item.address})`: ''}`,
        }))
      );
      updateOrganizationIdInUrl(newOrganization.id);
    }
  }, [organizationsDB]);

  useEffect(() => {
    forceRequest();
  }, [company])

  useEffect(() => {
    if (!organization || !user) return;

    //@ts-ignore
    const userOrganizations = user?.organizations;
    const organizationRoles = userOrganizations?.[organization.id]?.roles;
    const isAdmin = user!.roles.find((role) => role.name === 'admin');

    if(isAdmin) {
      setOrganizationUerRoles([{id: 0, name: 'admin'},...(organizationRoles || [])] || [])
    } else {
      setOrganizationUerRoles(organizationRoles || [])
    }
  }, [organization, user])

  const isLoadingToHandleChangedCompany = company?.id !== organization?.companyId;

  const updateOrganizationIdInUrl = (organizationId: number) => {
    if(organizationId === organization?.id) return;

    const locationArray = location.pathname.split('/');
    const organizationIndex = locationArray.indexOf('organization');

    if(organizationIndex !== -1) {
      locationArray[`${organizationIndex + 1}`] = `${organizationId}`;
      const searchQuery = location.search ? `${location.search}` : '';
      const newOrganizationUrl = `${locationArray.join('/')}${searchQuery}`;

      // we don't need extra redirect if url the same
      if(location.pathname !== newOrganizationUrl) {
        navigate(newOrganizationUrl);
      }

      return;
    }
  }

  const handleOrganization = (organization: OrganizationType | null) => {
    setOrganization(organization);

    const organizationId = organization?.id;

    if (organizationId) {

      updateOrganizationIdInUrl(organizationId);
    }
  }

  const updateOrganization = ({organizationId, organization, callback}: {organizationId: number, organization: OrganizationType, callback?: () => void }) =>
    apiEditOrganization(organization.id, organization)
      .then(updatedOrganizationData => {
        const newOrganizations = organizations.map((org) => {
          if (org.id === organizationId) {
            return updatedOrganizationData.data;
          }

          return org;
        });

        setOrganizationsDB(newOrganizations);
        setOrganization(updatedOrganizationData.data);
        showNotification({
          type: 'success',
          content: 'Успішно відредаговно',
        });
        callback?.();
      })
      .catch(() => {
        showNotification({ type: 'error' });
      })

  const value = useMemo(
    () => ({
      organization: organization as OrganizationType,
      organizationId: organization?.id as OrganizationType['id'],
      organizations,
      organizationsOptions,
      organizationUerRoles,
      // Refactoring: pivot data
      // @ts-ignore
      organizationEmployeeId: user?.organizations?.[organization?.id]?.roles?.[0]?.pivot?.employeeId,
      setOrganization: handleOrganization,
      isLoading: isLoading || isLoadingToHandleChangedCompany,
      loadOrganizations: forceRequest,
      updateOrganization,
      timezone: organization?.timezone || 'Europe/Kyiv',
    }),
    [
      organization,
      organizations,
      organizationsOptions,
      organizationUerRoles,
      setOrganization,
      handleOrganization,
      isLoading,
      forceRequest,
      updateOrganization,
    ]
  );

  return (
    <OrganizationContext.Provider value={value}>
      {value.isLoading? (
        <SpinnerPage className="OrganizationProvider_provider" />
      ) : (
        children
      )}
    </OrganizationContext.Provider>
  );
};

export const useOrganization = () => useContext(OrganizationContext);
// const [token, setToken] = React.useState(null);
export default OrganizationProvider;
