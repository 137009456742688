import React, { FC } from 'react';

import cn from 'classnames';

import { CompanyDTO } from 'types/company';

import styles from './CompanyOption.module.scss';

export type CompanyOptionProps = {
  company: CompanyDTO;
  className?: string;
  onClick?: () => void;
  selected?: boolean;
}

const CompanyOption: FC<CompanyOptionProps> = ({company, className, onClick, selected = false }) => (
  <div className={cn(styles.companyOption, className, selected && styles.selected)} onClick={onClick}>
    <div className={styles.companyLogoBox}>
      {company.image ? (
        <img src={company.image} alt={company.name}/>
      ) : (
        <div>{company.name.substr(0, 2).toUpperCase()}</div>
      )}
    </div>
    <div>
      {company.name}
    </div>
  </div>
)

export default CompanyOption;
