import api from 'api/api';
import { TableQueryParams } from 'components/Table/types';

import { ApiResponse, ApiTableResponse } from './apiTypes';

import { OrganizationType, SpecialistDTO } from '../components/types';

export const getOrganizationsApi = (): Promise<
  ApiResponse<OrganizationType[]>
> => api.get('/administration/organizations');
export const getOrganizationsOwnerApi = (): Promise<
  ApiResponse<OrganizationType[]>
> => api.get('/administration/organizations');

export const getOrganizationsByCompanyIdApi = ({companyId}: {companyId: number}): Promise<
  ApiResponse<OrganizationType[]>
  > => api.get(`administration/company/${companyId}/organizations`);

export const getAllOrganizationsApi = ({companyId, query}: {
  companyId: number;
  query: TableQueryParams;
}): Promise<ApiTableResponse<SpecialistDTO[]>> => api.get(`administration/company/${companyId}/organizations_table_format`, query);

export const apiEditOrganization = (
  id: number,
  data: OrganizationType
): Promise<ApiResponse<OrganizationType>> =>
  api.patch(`/administration/organizations/${id}`, data);

export const apiCreateOrganization = (
  data: OrganizationType,
  companyId: number,
): Promise<ApiResponse<OrganizationType[]>> =>
  api.post(`/administration/company/${companyId}/organizations`, data);
export const apiDeleteOrganization = (
  organizationId: number
): Promise<ApiResponse<OrganizationType[]>> =>
  api.delete(`/administration/organizations/${organizationId}`);
