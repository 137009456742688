import React, { ChangeEvent, FC, forwardRef, ReactNode, useImperativeHandle, useRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import cn from 'classnames';

import useFileUpload from 'hooks/useFileUpload';
import { i18nt } from 'translations/i18n';

import styles from './ImageUpload.module.scss';

export interface ImageUploadProps {
  className?: string;
  onUpload: (imageUrl: string | undefined) => void;
  imageUrl?: string;
  placeholder?: ReactNode;
  readonly?: boolean;
}

// forwardRef({
//   className,
//   onUpload,
//   imageUrl,
//   placeholder,
//   readonly,
// }: ImageUploadProps, ref) => {


// anyway with new react version we can skip usage forwardRef
// eslint-disable-next-line react/display-name
const ImageUpload = forwardRef(({
  className,
  onUpload,
  imageUrl,
  placeholder,
  readonly,
}:ImageUploadProps, ref) => {
  const {
    file,
    fileUrl,
    uploadFile,
    clearFile,
    isLoading,
    error,
  } = useFileUpload();
  const refInput = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    inputClick: () => refInput?.current?.click(),
    clear: handleClear,
  }));

  const handleClear = () => {
    clearFile();
    onUpload(undefined);
  };
  const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const file: File = event.target.files[0];

    if (file) {
      uploadFile(file).then(onUpload);

      return;
    }

    handleClear();
  };
  const url = imageUrl || fileUrl;

  const PlaceHolderComponent = placeholder;

  return (
    <div
      className={cn(
        styles.component,
        { [styles.placeHolderMode]: !url && !placeholder },
        className
      )}
    >
      {!url && (
        <>
          {/*@ts-ignore*/}
          {!!placeholder && (
            // @ts-ignore
            <PlaceHolderComponent onClick={() => ref?.current?.click()} />
          )}
          {!placeholder && (
            <div
              className={styles.placeholder}
              onClick={() => refInput?.current?.click()}
            >
              {i18nt('CLICK_TO_UPLOAD_IMAGE')}
            </div>
          )}
        </>
      )}
      {url && (
        <div className={styles.imagePreview}>
          <img src={url} />
          {!readonly && (
            <div className={styles.clearButtonBox} onClick={handleClear}>
              <ClearIcon />
            </div>
          )}

        </div>
      )}
      <input
        type="file"
        className={styles.inputPlaceholder}
        ref={refInput}
        onChange={handleFile}
        accept="image/*"
      />
    </div>
  );
})

export default ImageUpload;
