import React, { FC } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import CompanyOptionPreview from 'components/LayoutWithMenu/CompanyDropdown/CompanyOptionPreview/CompanyOptionPreview';
import { i18nt } from 'translations/i18n';
import { CompanyDTO } from 'types/company';

import styles from './CompanyPreview.module.scss';

export type CompanyPreviewProps = {
  company?: CompanyDTO | null;
  open?: boolean;
  handleClick?: () => void;
  handleCreateNewCompany?: () => void;
  className?: string;
}

const CompanyPreview: FC<CompanyPreviewProps> = ({company, open, handleClick, handleCreateNewCompany, className}) => (
  <div className={cn(styles.preview, open && styles.open, className)}>
    {company ? (
      <div className={styles.previewExistedCompany} onClick={handleClick}>
        <CompanyOptionPreview company={company}/>
        <IconButton className={styles.iconButton}>
          {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </IconButton>
      </div>
    ): (
      <div className={styles.addNew} onClick={handleCreateNewCompany} style={{height: '57px'}}><Icon name="addSquare" className={styles.addNewIcon}/>{i18nt('CREATE_COMPANY')}</div>
    )}

  </div>
)

export default CompanyPreview;
