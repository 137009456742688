import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import { MENU_ITEM } from 'components/MenuList/types';
import { i18nt } from 'translations/i18n';

import styles from './MenuList.module.scss';

export type MenuListProps = {
  items: MENU_ITEM[];
  disabledTitle?: string;
  onLogout?: () => void;
  className?: string;
  onClickItem?: () => void;
  baseUrl: string;
}

const MenuList: FC<MenuListProps> = ({ items, disabledTitle, onLogout, onClickItem, baseUrl }) => {

  const handleClickItem = () => {
    onClickItem?.();
  }

  return (
    <div className={styles.component}>
      {items.map((item) => {

        const { isActive, isHidden, link, label, icon } = item;

        if(isHidden) return null;

        return (
          <div
            className={cn(styles.item, { [styles.disabled]: !isActive })}
            title={isActive ? '' : disabledTitle}
            key={`Menu-Item-${label}`}
            onClick={handleClickItem}
          >
            {isActive && link ? (
              <NavLink
                to={link}
                className={styles.NavLink}
                // end //commented for support services sub pages (custom settings by optional feature)
              >
                {icon && (
                  <div className={styles.icon}>
                    {icon}
                  </div>
                )}
                {label}
              </NavLink>
            ) : (
              <a className={styles.NavLink}>
                {icon && (
                  <div className={styles.icon}>
                    {icon}
                  </div>
                )}
                {label}
              </a>
            )}
          </div>
        );
      })}

      <div className={cn(styles.item)}>
        <NavLink to={`${baseUrl}/settings`} className={styles.NavLink}>
          <div className={styles.icon}>
            <Icon name="settings"/>
          </div>
          {i18nt('ACCOUNT_SETTINGS')}
        </NavLink>
      </div>

      {onLogout && (
        <div className={cn(styles.item)}>
          <a onClick={onLogout} className={styles.NavLink}>
            <div className={styles.icon}>
              <Icon name="exit"/>
            </div>
            {i18nt('EXIT')}
          </a>
        </div>
      )}
    </div>
  )
}

export default MenuList;
