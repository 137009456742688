import React, { FC } from 'react';

import cn from 'classnames';

import { useAuth } from 'components/pages/Auth/AuthProvider';
import { CompanyDTO } from 'types/company';

import stylesCompanyOption from './../CompanyOption/CompanyOption.module.scss';
import styles from './CompanyOptionPreview.module.scss';

export type CompanyOptionProps = {
  company: CompanyDTO;
  className?: string;
  onClick?: () => void;
  selected?: boolean;
}

const CompanyOptionPreview: FC<CompanyOptionProps> = ({company, className, onClick, selected = false }) => {

  const { user } = useAuth();

  return (
    <div className={cn(stylesCompanyOption.companyOption, className, selected && stylesCompanyOption.selected)} onClick={onClick}>
      <div className={stylesCompanyOption.companyLogoBox}>
        {company.image ? (
          <img src={company.image} alt={company.name}/>
        ) : (
          <div>{company.name.substr(0, 2).toUpperCase()}</div>
        )}
      </div>
      <div>
        <div className={styles.companyName}>{company.name}</div>
        <div className={styles.userLink}>{user!.email}</div>
      </div>
    </div>
  )
}

export default CompanyOptionPreview;
