import React, { FC, PropsWithChildren } from 'react';

import OrganizationProvider from 'providers/OrganizationProvider';
import CompaniesProvider from 'providers/CompaniesProvider';
import CalendarEventProvider from 'providers/CalendarEventProvider';
import LayoutWithMenu from 'components/LayoutWithMenu/LayoutWithMenu';

const InnerPagesDataWrapper: FC<PropsWithChildren<any>> = ({ children }) => (
  <CompaniesProvider>
    <OrganizationProvider>
      <CalendarEventProvider>
        <LayoutWithMenu>{children}</LayoutWithMenu>
      </CalendarEventProvider>
    </OrganizationProvider>
  </CompaniesProvider>
);

export default InnerPagesDataWrapper;
