import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';
import MenuList from 'components/MenuList/MenuList';
import { MENU_ITEM, MENU_ITEM_MAP, MenuItemKey } from 'components/MenuList/types';
import useAccount from 'hooks/useAccount';
import { useCompanies } from 'providers/CompaniesProvider';
import { useDevice } from 'providers/DeviceProvider';
import { useOrganization } from 'providers/OrganizationProvider';
import { i18nt } from 'translations/i18n';

import { useAuth } from '../pages/Auth/AuthProvider';

const MENU_ORDER = [
  MenuItemKey.ORGANIZATION,
  MenuItemKey.EMPLOYEES,
  MenuItemKey.CALENDAR,
  MenuItemKey.CALENDAR_SCHEDULE_EMPLOYEES,
  MenuItemKey.SERVICES,
  MenuItemKey.REPORTS,
  MenuItemKey.VISITS,
  MenuItemKey.CLIENTS,
  MenuItemKey.FEEDBACKS,
];

const MENU_ORDER_MOBILE = [
  MenuItemKey.ORGANIZATION,
  MenuItemKey.EMPLOYEES,
  MenuItemKey.CALENDAR,
  MenuItemKey.CALENDAR_SCHEDULE_EMPLOYEES,
  MenuItemKey.SERVICES,
  MenuItemKey.REPORTS,
  MenuItemKey.VISITS,
  MenuItemKey.CLIENTS,
  MenuItemKey.FEEDBACKS,
];

const MenuUser = () => {
  const { i18n } = useTranslation();
  const { isMobile } = useDevice();
  const { onLogout } = useAuth();
  const { organization: {isEmployee, isManagement}} = useAccount();
  const { organization, organizationUerRoles } = useOrganization();
  const { company } = useCompanies();

  const organizationExisted = !!organization;
  const companyExisted = !!company;
  const moderatorUrlAvailable = organizationExisted && isManagement;

  const getDisabledTitle = () => {
    if (!organizationExisted) {
      return i18nt('FIRST_CREATE_AN_ORGANIZATION');
    }
  };
  const disabledTitle = getDisabledTitle();

  const companyBaseUrl = `/company/${company?.id}`;
  const organizationBaseUrl = `${companyBaseUrl}/organization/${organization?.id}`;

  const MENU: MENU_ITEM_MAP = {
    ORGANIZATION: {
      link: `${organizationBaseUrl}/details`,
      icon: <Icon name="home"/>,
      label: i18nt('ORGANIZATION_INFORMATION'),
      isActive: organizationExisted,
    },
    EMPLOYEES: {
      link: `${organizationBaseUrl}/employees`,
      icon: <Icon name="employees"/>,
      label: i18nt('EMPLOYEES'),
      isActive: organizationExisted,
    },
    CALENDAR: {
      link: `${organizationBaseUrl}/calendarSchedule`,
      icon: <Icon name="calendar"/>,
      label: i18nt('CALENDAR'),
      isActive: organizationExisted,
    },
    CALENDAR_SCHEDULE_EMPLOYEES: {
      link: `${organizationBaseUrl}/calendarScheduleEmployees`,
      icon: <Icon name="scheduling"/>,
      label: i18nt('SCHEDULE_OF_EMPLOYEES'),
      isActive: organizationExisted,
    },

    THEMING: {
      link: `${companyBaseUrl}/themingMobileApp`,
      label: i18nt('THEMEIZATION'),
      isActive: companyExisted,
      isHidden: !moderatorUrlAvailable,
    },
    SERVICES: {
      link: `${organizationBaseUrl}/services`,
      icon: <Icon name="list"/>,
      label: i18nt('ORGANIZATION_SERVICES'),
      isActive: organizationExisted,
      isHidden: !moderatorUrlAvailable,
    },
    ORGANIZATIONS: {
      link: `${companyBaseUrl}/organizations`,
      icon: <Icon name="organizations"/>,
      label: i18nt('ALL_COMPANY_ORGANIZATIONS'),
      isActive: companyExisted,
      isHidden: !moderatorUrlAvailable,
    },
    REPORTS: {
      link: `${organizationBaseUrl}/reports`,
      icon: <Icon name="chartMenu"/>,
      label: i18nt('REPORTS'),
      isActive: organizationExisted,
      // isHidden: !moderatorUrlAvailable
    },
    VISITS: {
      link: `${organizationBaseUrl}/visits`,
      icon: <Icon name="visits"/>,
      label: i18nt('VISITS'),
      isActive: organizationExisted,
    },
    CLIENTS: {
      link: `${organizationBaseUrl}/clients`,
      icon: <Icon name="clients"/>,
      label: i18nt('CLIENTS'),
      isActive: organizationExisted,
      isHidden: !moderatorUrlAvailable,
    },
    FEEDBACKS: {
      link: `${organizationBaseUrl}/feedbacks`,
      icon: <Icon name="feedbacks"/>,
      label: i18nt('FEEDBACKS'),
      isActive: organizationExisted,
      isHidden: !moderatorUrlAvailable,
    },
  };

  const menuOrder = isMobile ? MENU_ORDER_MOBILE : MENU_ORDER;

  const items: MENU_ITEM[] = useMemo(() => menuOrder.reduce((acc: MENU_ITEM[], menuItemKey) => {
    if (!MENU[menuItemKey]) return acc;

    return [...acc, MENU[menuItemKey]] as MENU_ITEM[];
  }, []), [organization, organizationUerRoles, i18n.language]);

  return (
    <MenuList items={items} onLogout={onLogout} disabledTitle={disabledTitle} baseUrl={companyBaseUrl}/>
  )
};

export default MenuUser;
