import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import cn from 'classnames';

import Icon from 'components/Icon/Icon';
import CompanyOption from 'components/LayoutWithMenu/CompanyDropdown/CompanyOption/CompanyOption';
import CompanyPreview from 'components/LayoutWithMenu/CompanyDropdown/CompanyPreview/CompanyPreview';
import MenuCompany from 'components/MenuCompany/MenuCompany';
import useAccount from 'hooks/useAccount';
import { useCompanies } from 'providers/CompaniesProvider';
import { useDevice } from 'providers/DeviceProvider';
import { i18nt } from 'translations/i18n';
import { CompanyDTO } from 'types/company';

import styles from './CompanyDropdown.module.scss';

export interface CompanyDropdownProps {}

const CompanyDropdown: FC<CompanyDropdownProps> = () => {
  const { organization: {isManagement}} = useAccount();
  const navigate = useNavigate();
  const {isMobile} = useDevice();

  const {company, companies, setCompany, isLoading} = useCompanies();
  const [open,setOpen] = useState<boolean>(false)

  const handleClick = () => {
    setOpen(value => !value)
  }
  const previewRef = useRef<HTMLDivElement | null>(null);
  const handleSelectOption = (company: CompanyDTO) => () =>  {
    setCompany(company);
    setOpen(false);
  };

  const handleCreateNewCompany = () => {

    navigate('/addCompany');
    setOpen(false);
  }
  
  return (
    <div className={styles.component}>
      {!isLoading && (
        <>
          <div ref={previewRef}>
            <CompanyPreview
              company={company}
              handleClick={handleClick}
              handleCreateNewCompany={handleCreateNewCompany}
              open={open}
            />
          </div>

          <Drawer
            anchor="left"
            open={open}
            onClose={() => setOpen(!open)}
            style={{ width: previewRef?.current?.offsetWidth }}
            className={styles.drawer}
          >
            <div onClick={handleClick} className={styles.backLine}>
              <IconButton className={styles.backIcon}>
                <ExpandMoreIcon />
              </IconButton>
              {i18nt('TO_THE_MAIN_MENU')}
            </div>
            {/*{isMobile && (*/}
            <CompanyPreview
              company={company}
              handleClick={handleClick}
              handleCreateNewCompany={handleCreateNewCompany}
              open={open}
              className={styles.companyPreviewMobile}
            />
            {/*)}*/}

            {isManagement && (
              <div className={styles.companyMenu}>
                <MenuCompany onClickItem={() => setOpen(false)} />
              </div>
            )}

            <div className={styles.companiesListTitle}>{i18nt('LIST_OF_COMPANIES')}:</div>
            <div className={cn(styles.companiesList, (companies?.length || 0 > 5) && styles.visibleScroll)}>
              {companies?.map(companyItem => (
                <CompanyOption
                  company={companyItem}
                  key={companyItem.id}
                  className={cn(styles.listItem, isMobile && styles.listItemMobile)}
                  selected={company?.id === companyItem?.id}
                  onClick={handleSelectOption(companyItem)}
                />
              ))}
              <div className={styles.poppoverFooter}>
                <div className={styles.addNew} onClick={handleCreateNewCompany}>
                  <Icon name="addSquare" className={styles.addNewIcon} />
                  {i18nt('CREATE_COMPANY')}
                </div>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default CompanyDropdown;
