// eslint-disable-next-line import/order
import React, { FC, useMemo } from 'react';

// import { useRouteMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Icon from 'components/Icon/Icon';
import MenuList from 'components/MenuList/MenuList';
import { MENU_ITEM, MENU_ITEM_MAP, MenuItemKey } from 'components/MenuList/types';
import { useCompanies } from 'providers/CompaniesProvider';
import { useDevice } from 'providers/DeviceProvider';
import { i18nt } from 'translations/i18n';

export interface IMenuAdmin {
  onClickItem?: () => void
}

const MENU_ORDER = [
  MenuItemKey.THEMING,
  MenuItemKey.ORGANIZATIONS,
  MenuItemKey.COMPANY_HOME_PAGE,
  MenuItemKey.COMPANY_EMPLOYEE,
];

const MENU_ORDER_MOBILE = [
  MenuItemKey.THEMING,
  MenuItemKey.ORGANIZATIONS,
  MenuItemKey.COMPANY_HOME_PAGE,
  MenuItemKey.COMPANY_EMPLOYEE,
];

const MenuCompany: FC<IMenuAdmin> = ({ onClickItem }) => {
  const { i18n } = useTranslation();
  const { isMobile } = useDevice();
  const { company, companyUerRoles } = useCompanies();

  const companyExisted = !!company;
  const moderatorUrlAvailable = Boolean(companyUerRoles.find((role: { name: string; }) => role.name === 'moderator' || role.name === 'admin' || role.name === 'owner'));

  const baseUrl = `/company/${company?.id}`;

  const MENU: MENU_ITEM_MAP = {
    COMPANY_HOME_PAGE: {
      link: baseUrl,
      icon: <Icon name="companyInfo"/>,
      label: i18nt('COMPANY_INFORMATION'),
      isActive: companyExisted,
    },
    THEMING: {
      link: `${baseUrl}/themingMobileApp`,
      icon: <Icon name="theming"/>,
      label: i18nt('THEMEIZATION'),
      isActive: companyExisted,
      isHidden: !moderatorUrlAvailable,
    },
    ORGANIZATIONS: {
      link: `${baseUrl}/organizations`,
      icon: <Icon name="organizations"/>,
      label: i18nt('ALL_COMPANY_ORGANIZATIONS'),
      isActive: true,
    },
    COMPANY_EMPLOYEE: {
      link: `${baseUrl}/company_employee`,
      icon: <Icon name="employees"/>,
      label: i18nt('COMPANY_EMPLOYEES'),
      isHidden: !moderatorUrlAvailable,
      isActive: moderatorUrlAvailable,
    },
  };

  const menuOrder = isMobile ? MENU_ORDER_MOBILE : MENU_ORDER;

  const items: MENU_ITEM[] = useMemo(() => menuOrder.reduce((acc: MENU_ITEM[], menuItemKey) => {
    if (!MENU[menuItemKey]) return acc;

    return [...acc, MENU[menuItemKey]] as MENU_ITEM[];
  }, []), [i18n.language]);

  return (
    <MenuList items={items} onClickItem={onClickItem} baseUrl={baseUrl}/>
  )
};

export default MenuCompany;
