import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

// import { useRouteMatch } from "react-router-dom";
import cn from 'classnames';

import { i18nt } from 'translations/i18n';

import { useAuth } from '../pages/Auth/AuthProvider';

import styles from './MenuAdmin.module.scss';

export interface IMenuAdmin {}

const MenuAdmin: FC<IMenuAdmin> = () => {
  const { onLogout } = useAuth();

  return (
    <div className={styles.component}>
      <div className={cn(styles.item)}>
        <NavLink to="/admin/organizations">{i18nt('ORGANIZATIONS')} (Admin)</NavLink>
      </div>
      <div className={cn(styles.item)}>
        <NavLink to="/admin/users">{i18nt('USERS')} (Admin)</NavLink>
      </div>
      <div className={cn(styles.item)}>
        <a onClick={onLogout}>{i18nt('EXIT')}</a>
      </div>
    </div>
  );
};

export default MenuAdmin;
