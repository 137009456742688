import { TableQueryParams } from 'components/Table/types';
import { SpecialistDTO, SpecialistSalaryHistoryDTO } from 'components/types';
import { VisitDTO } from 'types/visits';

import api from './api';
import { ApiResponse, ApiTableResponse } from './apiTypes';

export const getAllEmployeesApi = (): Promise<ApiResponse<SpecialistDTO[]>> => api.get('/administration/employees ');

// export const apiGetEmployeesByOrganizationId = (organizationId: number): Promise<ApiResponse<SpecialistDTO[]>> => api.get(`/employees?organization_id=${organizationId}`);
export const apiGetEmployeesByOrganizationId = ({
  organizationId,
  companyId,
  query,
  showRequestError,
}: {
  organizationId: number;
  companyId: number;
  query?: Record<string, any>;
  showRequestError?: boolean;
}): Promise<ApiResponse<SpecialistDTO[]>> =>
  api.get(`/administration/company/${companyId}/organization/${organizationId}/employees`, query, { showRequestError });

export const apiCreateEmployeeByOrganizationId = ({
  organizationId,
  data,
  companyId,
}: {
  organizationId: number;
  data: SpecialistDTO;
  companyId: number;
}): Promise<ApiResponse<SpecialistDTO[]>> =>
  api.post(`/administration/company/${companyId}/organization/${organizationId}/employees`, data, {
    showRequestError: false,
  });

export const apiEditEmployeeByOrganizationId = ({
  organizationId,
  employeeId,
  companyId,
  data,
}: {
  organizationId: number;
  employeeId: number;
  companyId: number;
  data: SpecialistDTO;
}): Promise<ApiResponse<SpecialistDTO[]>> =>
  api.patch(`/administration/company/${companyId}/organization/${organizationId}/employees/${employeeId}`, data);

export const apiDeleteEmployee = ({
  organizationId,
  employeeId,
  companyId,
}: {
  organizationId: number;
  employeeId: number;
  companyId: number;
}): Promise<ApiResponse<SpecialistDTO[]>> =>
  api.delete(`/administration/company/${companyId}/organization/${organizationId}/employees/${employeeId}`);

export const apiEmployeeReSendInvitation = ({
  organizationId,
  employeeId,
  companyId,
}: {
  organizationId: number;
  employeeId: number;
  companyId: number;
}): Promise<ApiResponse<SpecialistDTO[]>> =>
  api.get(`/administration/company/${companyId}/organization/${organizationId}/employee_invitation/${employeeId}`);

export const apiEmployeeTable = ({
  organizationId,
  companyId,
  query,
}: {
  organizationId: number;
  companyId: number;
  query: TableQueryParams;
}): Promise<ApiTableResponse<SpecialistDTO[]>> =>
  api.get(`/administration/company/${companyId}/organization/${organizationId}/employees_table_format`, query);

export const apiCompanyEmployeeTable = ({
  companyId,
  query,
}: {
  companyId: number;
  query: TableQueryParams;
}): Promise<ApiTableResponse<SpecialistDTO[]>> =>
  api.get(`/administration/company/${companyId}/users_table_format`, query);

export const apiGetEmployeeSalaryHistory = ({
  organizationId,
  employeeId,
  companyId,
}: {
  organizationId: number;
  employeeId: number;
  companyId: number;
}): Promise<ApiResponse<SpecialistSalaryHistoryDTO>> =>
  api.get(`/administration/company/${companyId}/organization/${organizationId}/employee/${employeeId}/salaries`);
