export const WidgetsColorsList = [
  '#A5D6A7',
  '#DCE775',
  '#FFD54F',
  '#BCAAA4',
  '#81D4FA',
  '#4DB6AC',
  '#B39DDB',
  '#00BCD4',
  '#01579B',
  '#00BFA5',
  '#B2FF59',
  '#FFAB91',
  '#CFD8DC',
  '#4CAF50',
  '#EA80FC',
  '#F4FF81',
  '#BCAAA4',
  '#EF9A9A',
  '#B0BEC5',
  '#0277BD',
  '#AFB42B',
  '#E65100',
  '#6A1B9A'
];
