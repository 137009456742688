import React, { FC, Suspense } from 'react';
import { withTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import CustomRoute from 'components/CustomRoute/CustomRoute';
import LoginPage from 'components/pages/Auth/LoginPage/LoginPage';
import RegistrationPage from 'components/pages/Auth/RegistrationPage/RegistrationPage';
import CreateNewCompanyPage from 'components/pages/CreateNewCompanyPage/CreateNewCompanyPage';
import InnerPagesDataWrapper from 'app/InnerPagesDataWrapper';

const InnerPages = React.lazy(() => import('./InnerPages'));

const Routing: FC = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    <Route path="/restore_access_confirm" element={<LoginPage mode="restoreAccessConfirm" />} />
    <Route path="/restore_access" element={<LoginPage mode="restoreAccess" />} />
    <Route path="/registration" element={<RegistrationPage />} />
    <Route path="/invite/registration" element={<LoginPage mode="invitation" />} />
    <Route path="/init_wizard" element={<CreateNewCompanyPage isWizardInitPage />} />
    <Route
      path="/*"
      element={
        <CustomRoute lazy>
          <InnerPagesDataWrapper>
            <InnerPages />
          </InnerPagesDataWrapper>

        </CustomRoute>
      }
    />
  </Routes>
);

export default withTranslation()(Routing);
