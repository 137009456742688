import { useCallback, useEffect, useState } from 'react';
import { useSetState } from 'react-use';

export type useApiResponse<T> = {
  isLoading: boolean;
  error: any;
  data: T | null;
  forceRequest: (args?: useApiArgs) => void;
};
export type ApiOptionsType<T> = {
  normalize?: (data: any) => T;
  autoFetch?: boolean;
};
interface State<T> {
  data: T | null;
  isLoading: boolean;
}

export type useApiArgs = any;

const useApi = <T>(
  apiMethod: (args: any) => Promise<any>,
  args?: useApiArgs,
  options?: ApiOptionsType<T>
): useApiResponse<T> => {
  const [{ data, isLoading }, setState] = useSetState<State<T>>({
    isLoading: true,
    data: null,
  });
  // const [isError, setIsError] = useState<string>();
  const [error, setError] = useState<string>();
  // const [ isLoaded, setIsLoaded ] =

  const handleRequest = useCallback(async (newArgs?: useApiArgs) => {
    setState({ data: null, isLoading: true });
    setError(undefined);

    try {
      const originData = await apiMethod(newArgs || args);

      const data = options?.normalize
        ? options.normalize(originData.data)
        : originData.data;

      setState({ data, isLoading: false });
    } catch (e) {
      setState({ isLoading: false, data: null });
      // @ts-ignore
      setError(e);
    }
  }, [setState, options?.normalize, args]);

  useEffect(() => {

    const isAutoFetch = options?.autoFetch !== false;

    if (isAutoFetch) {
      handleRequest();
    }
  }, [apiMethod, args]);

  return {
    isLoading,
    error,
    data: data as T,
    forceRequest: handleRequest,
  };

  // return useMemo(() => ({
  //     isLoading,
  //     error,
  //     data: data as T,
  //     forceRequest: handleRequest,
  // }), [
  //     isLoading,
  //     error,
  //     data,
  //     handleRequest,
  // ])
};

export default useApi;
