import { toSnakeCase } from 'utils/apiUppercase';

const prepareQuery = (query?: Record<string, string | number | boolean | undefined>) => {
  if (!query) return '';

  const localQuery = toSnakeCase(query);
  const queryKeys = Object.keys(localQuery);
  const queryValues = Object.values(localQuery);
  const queryArray = queryKeys.map((key, index) => {
    const value = queryValues[index];

    if(value === undefined || value === false) return false

    return `${key}=${queryValues[index]}`
  }).filter(i => i);

  return `?${queryArray.join('&')}`;
}

export default prepareQuery;
