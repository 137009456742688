import React, { FC, useEffect, useState } from 'react';

import SelectMy from 'components/Selects/SelectMy';
import { AutocompleteOptionType } from 'components/Selects/types';
import { useOrganization } from 'providers/OrganizationProvider';
import { i18nt } from 'translations/i18n';

import styles from './UserOrganizationsAutocomplete.module.scss';

interface OptionType {
  label: string;
  value: number | string;
}
const UserOrganizationsAutocomplete: FC = () => {
  const {
    organization,
    organizations,
    organizationsOptions,
    setOrganization,
  } = useOrganization();
  const [value, setValue] = useState<any | null>(null);

  useEffect(() => {
    //@ts-ignore
    setValue(organizationsOptions.find((o) => o.value === organization.id));
  }, [organization, organizationsOptions]);

  const handleChange = (newValue: AutocompleteOptionType<{}, string|number>) => {
    setValue(newValue);

    const selectedOrganization = organizations.find(
      (o) => o.id === newValue.value
    );

    if (!selectedOrganization) {
      alert('Error with selecting organization');

      return;
    }
    
    //@ts-ignore
    setOrganization(selectedOrganization);
  };

  if(organizationsOptions.length === 1) return null;

  return (
    <div className={styles.component}>
      <SelectMy<{}, string | number>
        value={value}
        //@ts-ignore
        onChange={handleChange}
        blurOnSelect
        disableClearable
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={organizationsOptions}
        className={styles.autoComplete}
        sx={{ width: 300 }}
        label={i18nt('MY_ORGANIZATIONS')}
        disableTextField
        //hook for correct data for select
        key={`my_organizations_select_${JSON.stringify(value)}`}
      />
    </div>
  );
};

export default UserOrganizationsAutocomplete;
