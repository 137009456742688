import React from 'react';

import Button, { ButtonProps } from 'components/Buttons/Button';
import { i18nt } from 'translations/i18n';

const ButtonAdd = ({variant = 'primary', children, startIcon = 'plusRegular', ...props}: ButtonProps) => (
  <Button variant={variant} startIcon={startIcon} {...props}>{children || i18nt('ADD')}</Button>
);

export default ButtonAdd;
