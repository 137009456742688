import axios, { AxiosResponse } from 'axios';
import queryString from 'query-string';

import { ApiConfig } from 'api/apiTypes';
import {getValidationMessage, ValidationMessageProps} from 'constants/texts';
import { toCamelCase, toSnakeCase } from 'utils/apiUppercase';
import { prepareQueryFilters } from 'utils/prepareQueryFilters';

import showNotification from '../components/Notifications/showNotification';

const domainConfig = process.env.REACT_APP_API_URL !== undefined && process.env.REACT_APP_API_URL.replace('https://', '').replace('http://', '').replace('www.', '').replace('/api', '');

export const ENV_DOMAIN = domainConfig || 'app.gil.com.ua';

export const apiUrl = `https://${ENV_DOMAIN}/api`;

// for handling showError: false
const promiseWrapper = <T>(promise: Promise<T>, config?: ApiConfig) => {
  promise.catch((error) => {
    if (config?.showRequestError !== false) {
      const errorsData = error?.response?.data?.errors;

      errorsData.forEach((errorData: ValidationMessageProps) => {
        const predefinedErrorText = getValidationMessage(errorData);

        showNotification({ type: 'error', content: predefinedErrorText });
      });
    }

    return Promise.reject(error);
  });

  return promise;
};

const apiGenerator = () => {
  const instance = axios.create({
    baseURL: apiUrl,
  });

  //@ts-ignore
  window.appInfo = {
    //@ts-ignore
    ...(window?.appInfo || {}),
    APP_VERSION: process.env.REACT_APP_VERSION,
    apiUrl,
  }

  instance.interceptors.request.use(
    //@ts-ignore
    function (config: ApiConfig) {

      // Do something before request is sent
      const token = config?.forceToken || localStorage.getItem('token');

      if (token && config?.headers) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      const transFormCase =
        config?.transformCase !== false ? true : config?.transformCase;

      if (transFormCase) {
        return {
          ...config,
          data: toSnakeCase(config.data),
        };
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      const config = response?.config as ApiConfig;

      //@ts-ignore
      const transFormCase =
        config.transformCase !== false ? true : config.transformCase;

      if (response.data && transFormCase) {
        return {
          ...response,
          data: toCamelCase(response.data),
        };
      }

      return response;
    },
    function (error) {
      // console.log({error, a, b, c})
      // // console.log(error);
      // showNotification({type: 'error', content: 'error'})
      // Do something with response error
      return Promise.reject(error);
    }
  );

  const get = function <T, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ApiConfig
  ) {
    const urlWithQuery = data && Object.keys(data)?.length ? `${url}?${queryString.stringify(prepareQueryFilters(toSnakeCase(data)))}` : url;

    //@ts-ignore
    return promiseWrapper<T>(instance.get(urlWithQuery, { ...config }), config);
  };

  const post = function <T, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ApiConfig
  ) {
    return promiseWrapper<T>(instance.post(url, data, config), config);
  };

  const put = function <T, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ApiConfig
  ) {
    return promiseWrapper<T>(instance.put(url, data, config), config);
  };

  const patch = function <T, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ApiConfig
  ) {
    return promiseWrapper<T>(instance.patch(url, data, config), config);
  };

  const deleteFunc = function <T, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: ApiConfig
  ) {
    return promiseWrapper<T>(instance.delete(url, data), config);
  };

  return {
    get,
    post,
    put,
    patch,
    delete: deleteFunc,
  };
};

// @ts-ignore
const api = apiGenerator();

export const getApiCodeError = (e: any, params?: Partial<ValidationMessageProps>) => {
  const code = e?.response?.data?.errors?.[0]?.code;

  return getValidationMessage({code, ...params});
}

// @ts-ignore
export default api;
