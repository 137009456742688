import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';

import translationEN from './en.json';
import translationFR from './fr.json';
import translationUA from './ua.json';
// import translationRU from './ru.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ua: {
    translation: translationUA
  },
  fr: {
    translation: translationFR
  }
};

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en",
    fallbackLng: {
      'ru': ['ua'],
      'uk': ['ua'],// Fallback to 'ua' for Russian
      'ua': ['ua'],
      'default': ['en'],
    },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      // transSupportBasicHtmlNodes: true,
      // transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      // useSuspense: true,
    }
  });

// const i18nt = i18n;

export type TranslationsKeys = keyof typeof translationUA;
export type TranslationsType = {
  [key in TranslationsKeys]: string;
};

//Tried but somehow it doesn't work (translation don't apply)
// need to try in future
// export type TranslationsType = NestedKeys<typeof translationUA>;

// eslint-disable-next-line import/no-named-as-default-member
export const i18nt = i18n.t.bind(i18n) as (value: (keyof TranslationsType), options?: Record<string, string | number>) => string;

//@ts-ignore
window.i18n = i18n;
export default i18n;
