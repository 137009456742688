import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { format } from 'date-fns';
import _ from 'lodash';

import { apiGetOrders, apiOrderCancel, apiPatchOrder, apiPostOrders } from 'api/orders';
import prepareQuery from 'api/prepareQuery';
import { OrderDTO, SpecialistDTO } from 'components/types';
import { WidgetsColorsList } from 'components/Widgets/widgetColors';
import useOrganizationEmployees from 'hooks/useOrganizationEmployees';
import { useCompanies } from 'providers/CompaniesProvider';
import { useOrganization } from 'providers/OrganizationProvider';
import { CalendarScheduleItem } from 'types/calendar';
import { dateFormat, dateFormats } from 'utils/dateUtil';

type LoadEventsQuery = undefined | { start: Date; end: Date };

export type CalendarEventsEmployee = SpecialistDTO & {color: string};
type CalendarEventsContextType = {
  loadEvents: (arg0: LoadEventsQuery) => unknown;
  updateEvent: (event: OrderDTO, callback: () => void, isForced?: boolean) => Promise<void>;
  addEvent: (event: OrderDTO, callback: () => void, isForced?: boolean) => Promise<void>;
  events: CalendarScheduleItem<OrderDTO>[];
  setEvents: (events: CalendarScheduleItem<OrderDTO>[]) => void;
  isLoading: boolean;
  deleteEvent: (event: OrderDTO, cancelReason?: string) => Promise<OrderDTO>;
  employees: CalendarEventsEmployee[];
  isFullScreen: boolean;
  setIsFullScreen: (value: boolean) => void;
};
const CalendarEventsContextInitialState = {
  loadEvents: _.noop,
  updateEvent: Promise,
  addEvent: Promise,
  events: [],
  setEvents: _.noop,
  isLoading: false,
  deleteEvent: _.noop,
  employees: [],
  isFullScreen: false,
  setIsFullScreen: _.noop,
};

const CalendarEventsContext = React.createContext<CalendarEventsContextType>(
  //@ts-ignore
  CalendarEventsContextInitialState
);

const convertOrderToCalendarEvent = (
  order: OrderDTO
): CalendarScheduleItem<OrderDTO> => ({
  id: order.id,
  title: order?.employee?.name || order?.employee?.name,
  start: new Date(`${order.date} ${order.timeStart}`),
  end: new Date(`${order.date} ${order.timeEnd}`),
  originData: {
    ...order,
    servicesIds: order.servicesIds.map((item: any) => Number(item)),
  },
});

const convertInnerEventToDBEvent = (event: any) => ({
  ...event,
  date: format(new Date(event.date), dateFormats.dateView),
  servicesIds: event.servicesIds.map((item: any) => Number(item)),
  timeStart:  format(new Date(event.timeStart), dateFormats.time),
  timeEnd:  format(new Date(event.timeEnd), dateFormats.time),
});

const CalendarEventProvider: FC = ({ children }) => {
  const {organizationId} = useOrganization();
  const {companyId} = useCompanies();
  const {employees: organizationEmployees} = useOrganizationEmployees({companyId, organizationId});
  //@ts-ignore
  const employeesWithColors: CalendarEventsEmployee[] = useMemo(() => organizationEmployees.map((item, index) => ({...item, color: WidgetsColorsList[index]})), [organizationEmployees]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { organization } = useOrganization();
  const [events, setEvents] = useState<CalendarScheduleItem<OrderDTO>[]>([]);
  const [employees, setEmployees] = useState<CalendarEventsEmployee[]>([]);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const [eventsWithColors, setEventsWithColors] = useState<CalendarScheduleItem<OrderDTO>[]>([]);

  // handle events and add colors to them
  useEffect(() => {
    setEventsWithColors(events.map((item) => ({
      ...item,
      color: employeesWithColors.find((employee) => employee.id === item.originData.employee.id)?.color
    })));
    const wholeEmployeesList = getEmployeesList(events);
    const uniqueEmployeesListId = _.uniqBy(wholeEmployeesList, 'id').map(({id}) => id);
    const employeesWithEvents = employeesWithColors.reduce((acc: CalendarEventsEmployee[], employee) => {
      if (uniqueEmployeesListId.includes(employee.id)) return [...acc, employee];

      return acc;
    },[]);

    setEmployees(employeesWithEvents);
  }, [events, employeesWithColors]);

  const getEmployeesList = (events: CalendarScheduleItem<OrderDTO>[]) =>
    //TODO: fix types for originData
    //@ts-ignore
    events.map(({ originData }) => originData.employee)
  ;

  const loadEvents = (query : LoadEventsQuery) => {
    setIsLoading(true);

    const preparedQuery = (query?.start && query?.end) ? { startDate: dateFormat(query.start, dateFormats.dateView), endDate:  dateFormat(query.end, dateFormats.dateView) } : {};

    if (organization?.id) {
      apiGetOrders(organization.id, {
        ...preparedQuery,
        executor: true,
      })
        .then((result) => {
          const eventsMappedForCalendar = result.data.map((item) =>
            convertOrderToCalendarEvent(item)
          );

          setEvents(eventsMappedForCalendar);
        }).finally(() => {
          setIsLoading(false);
        });

    }
  }

  const updateEvent = (editedEvent: OrderDTO, callback?: (updatedEvent: OrderDTO) => void, forced?: boolean) => {
    const _data = convertInnerEventToDBEvent(editedEvent);

    return apiPatchOrder(organization!.id, _data, _data.id, prepareQuery({forced}), { showRequestError: false })
      .then(result => {
        const createdEventFromDB = result.data;
        const createdCalendarEventFromDB = convertOrderToCalendarEvent(createdEventFromDB)

        setEvents(
          events.map((event) =>
            event.id === editedEvent.id
              ? createdCalendarEventFromDB
              : event
          )
        );
        callback?.(createdEventFromDB);
      })
  };

  const deleteEvent = (event: OrderDTO, reason?: string) =>
    apiOrderCancel(organization!.id, event.id, reason)
      .then((response) => {
        setEvents(
          events.filter((ev) => ev.id !== event.id)
        );

        return response.data;
      });

  const addEvent = (editedEvent: OrderDTO, callback?: () => void, forced?: boolean) => {
    const _data = convertInnerEventToDBEvent(editedEvent);

    return apiPostOrders(organization!.id, _data, prepareQuery({forced}), { showRequestError: false })
      .then(result => {
        const createdEventFromDB = convertOrderToCalendarEvent(result.data)

        setEvents([ ...events, createdEventFromDB ]);
        callback?.();
      });

  };

  const value = useMemo(
    () => ({
      loadEvents,
      updateEvent,
      addEvent,
      events: eventsWithColors,
      setEvents,
      isLoading,
      deleteEvent,
      employees,
      isFullScreen,
      setIsFullScreen: (value: boolean) => setIsFullScreen(value),
    }),
    [
      loadEvents,
      updateEvent,
      addEvent,
      eventsWithColors,
      setEvents,
      isLoading,
      deleteEvent,
      employees,
    ]
  );

  return (
    <CalendarEventsContext.Provider value={value}>
      {children}
    </CalendarEventsContext.Provider>
  );
};

export const useCalendarEvents = () => useContext(CalendarEventsContext);

export default CalendarEventProvider;
