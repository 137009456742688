import React, { FC } from 'react';

import cn from 'classnames';

import styles from './ButtonsWrapper.module.scss';

interface ButtonsWrapperProps {
  className?: string;
  display?: 'block' | 'inline';
  align?: 'left' | 'right' | 'center';
}

const ButtonsWrapper: FC<ButtonsWrapperProps> = ({ className, align, display = 'inline', children}) => (
  <div className={cn(styles.component, styles?.[`align-${align}`], styles?.[display], className)}>
    {children}
  </div>
)

export default ButtonsWrapper;
