import api from 'api/api';
import { UserDTO } from 'components/types';
import { CompanyAndOrgPOSTDTO, CompanyDTO } from 'types/company';

import { ApiConfig, ApiResponse } from './apiTypes';

type CompanyQueryParams = {
  companyId: number;
}

export const apiCreateCompanyWithOrganization = (
  data: CompanyAndOrgPOSTDTO,
  config?: ApiConfig
): Promise<ApiResponse<CompanyAndOrgPOSTDTO>> =>
  api.post('/administration/company_and_organization', data, config);

export const apiGetCompanies = (): Promise<ApiResponse<CompanyDTO[]>> =>
  api.get('/administration/companies');

export const apiUpdateCompany = ({companyId, data}: CompanyQueryParams & {data: CompanyDTO}): Promise<ApiResponse<CompanyDTO>> =>
  api.patch(`/administration/company/${companyId}`, data);

export const apiGetCompanyEmployee = ({companyId}: CompanyQueryParams): Promise<ApiResponse<UserDTO[]>> =>
  api.get(`/administration/company/${companyId}/users`);

export const apiDeleteCompany = ({companyId}: CompanyQueryParams): Promise<ApiResponse<any>> =>
  api.delete(`/administration/companies/${companyId}`);

