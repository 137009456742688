import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';

import CompanyDropdown from 'components/LayoutWithMenu/CompanyDropdown/CompanyDropdown';
import { useAuth } from 'components/pages/Auth/AuthProvider';
import { useDevice } from 'providers/DeviceProvider';
import { useOrganization } from 'providers/OrganizationProvider';
import { i18nt } from 'translations/i18n';

import MenuAdmin from '../MenuAdmin/MenuAdmin';
import MenuUser from '../MenuUser/MenuUser';
import UserOrganizationsAutocomplete from '../UserOrganizationsAutocomplete/UserOrganizationsAutocomplete';

import styles from './LayoutWithMenu.module.scss';

const LayoutWithMenu: FC = ({ children }) => {
  const location = useLocation();
  const { isMobile, setShowMenu, showMenu } = useDevice();
  const { organizations, organizationId } = useOrganization();
  const { user } = useAuth();
  const isAdmin = user!.roles.find((role) => role.name === 'admin');

  useEffect(() => {
    setShowMenu(false);

    return () => {
      // fix for case when user logout
      setShowMenu(false);
    }
  }, [location]);

  const Menu = (
    <>
      <div className={styles.menu}>
        <div className={styles.companyDropDownWrapper}>
          <CompanyDropdown/>
        </div>

        {/*<MenuCompany />*/}
        {organizations.length > 0 && (
          <UserOrganizationsAutocomplete />
        )}
        <MenuUser />
        {isAdmin && (
          <>
            <div>{i18nt('MENU')} (Admin) </div>
            <MenuAdmin />
          </>
        )}
      </div>
    </>
  );

  return (
    <div className={styles.component}>
      {!isMobile && Menu}

      {isMobile && (
        <Drawer
          anchor="left"
          open={showMenu}
          onClose={() => setShowMenu(!showMenu)}
        >
          {Menu}
        </Drawer>
      )}
      <div className={styles.content} key={`organization-${organizationId}-layout`}>
        {children}
        {/*{isLoading ? null : children}*/}
      </div>
    </div>
  );
};

export default LayoutWithMenu;
