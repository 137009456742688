import React, { FC, useMemo, useState } from 'react';
import { Control, Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import cn from 'classnames';
import queryString from 'query-string';
import * as yup from 'yup';

import Box from 'components/Box/Box';
import Button from 'components/Buttons/Button';
import ImageUpload from 'components/ImageUpload/ImageUpload';
import Input from 'components/Input/Input';
import LayoutContent from 'components/LayoutContent/LayoutContent';
import { OrganizationGalleryItem } from 'components/OrganizationEditForm/OrganizationGallery/OrganizationGallery';
import OrganizationGallerySection, {
  ORGANIZATION_GALLERY_DEFAULT_ITEMS
} from 'components/OrganizationEditForm/OrganizationGallerySection/OrganizationGallerySection';
import PhoneInput from 'components/PhoneInput/PhoneInput';
import SelectMy from 'components/Selects/SelectMy';
import TimezoneAutocomplete from 'components/SelectsData/TimezoneAutocomplete';
import StepTitle from 'components/StepTitle/StepTitle';
import TimePicker from 'components/TimePicker/TimePicker';
import { getCurrenciesList } from 'constants/mockedData';
import { useCompanies } from 'providers/CompaniesProvider';
import { useDevice } from 'providers/DeviceProvider';
import { i18nt } from 'translations/i18n';
import { CompanyDTO, yupSchemeCompanyDTO } from 'types/company';
import { OrganizationDTO, yupSchemeOrganizationDTO } from 'types/organization';
import { timezoneCurrent } from 'utils/dateUtil';
import { domainChangeRules } from 'utils/domainRules';
import transliterate from 'utils/transliterate';
import ButtonDelete from 'components/Buttons/examples/ButtonDelete/ButtonDelete';

import styles from './CreateNewCompanyPage.module.scss';

type CompanyType = {
  name: string;
};

export interface CreateNewCompanyPageProps {
  company?: CompanyType;
  isWizardInitPage?: boolean;
}

const INITIAL_VALUES = {
  name: '',
  image: 'https://my.gil.com.ua/logo192.png',
  domain: '',
  organization: {
    name: '',
    address: '',
    phones: [''],
    image: '',
    workingHoursData: {
      start: '08:00',
      end: '18:00',
    },
  }
};

export const yupCreateNewCompany = yup
  .object({
    ...yupSchemeCompanyDTO,
    organization: yup
      .object(yupSchemeOrganizationDTO)
  })
  .required();

const CreateNewCompanyPage: FC<CreateNewCompanyPageProps> = ({ company, isWizardInitPage }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const { createCompanyWithOrganization} = useCompanies();

  const urlQuery = queryString.parse(location.search);
  const queryCompanyName = urlQuery.companyName as string;

  const currenciesOptions = useMemo(() => getCurrenciesList(timezoneCurrent), [timezoneCurrent]);

  const imageControlRef = React.useRef<{inputClick: () => void, clear: () => void}>(null);

  const formDefaultValue = {
    ...INITIAL_VALUES,
    timezone: timezoneCurrent,
    name: queryCompanyName || INITIAL_VALUES.name,
    domain: queryCompanyName?.length ? transliterate(queryCompanyName).replaceAll(' ','_').toLowerCase() : INITIAL_VALUES.domain,
    gallery: ORGANIZATION_GALLERY_DEFAULT_ITEMS,

    ...company,
  }

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    trigger,
    watch,

    formState: { errors, touchedFields, dirtyFields }
  } = useForm({
    resolver: yupResolver(yupCreateNewCompany),
    defaultValues: {
      ...formDefaultValue,
      organization: {
        ...formDefaultValue.organization,
        currency: currenciesOptions[0].value,

      }
    },
  });

  const handleUploadLogo = (imageUrl?: string) => {
    setValue('image', imageUrl || '');
    trigger('image');
  };

  const handleUploadMap = (imageUrl?: string) => {
    setValue('organization.image', imageUrl || '');
    trigger('organization.image');
  };

  const handleNameChange = (e: { target: { value: any; }; }) => {
    const value = e.target.value;

    setValue('name', value || '');
    trigger('name');

    // if(!getValues()?.domain.length && value.length) {
    if(!dirtyFields?.domain) {
      setValue('domain', transliterate(domainChangeRules(value)).replaceAll(' ','_').toLowerCase());
      trigger('domain');
    }
  }

  const isValidCustom = (() => {
    const data = watch();

    if(data.name?.length < 3) {
      return false
    }

    if(data?.organization?.name?.length < 3) {
      return false
    }

    return true;

  })();

  const memoGallery = useMemo(() => getValues().gallery, [getValues().gallery]);

  const handleOrganizationGalleryChange = (gallery: OrganizationGalleryItem[]) => {
    // setGallery(gallery);
    setValue('gallery', gallery);
    trigger('gallery');
  }

  const handleDomainChange = (onChange: (value: string) => void) => (e: { target: { value: any; }; }) => {
    onChange(domainChangeRules(e.target.value));
  }

  const handleSubmitForm = (data: any) => {
    createCompanyWithOrganization({data, isDefaultNavigation: false, config: isMobile ? {showRequestError:false} : undefined})
      .then(() => {
        if(isMobile) {
          navigate('/init_wizard_success')
        } else {
          navigate('/')
        }
      })
  }

  return (
    <LayoutContent
      title={i18nt('CREATING_NEW_COMPANY')}
      className={cn('CreateNewCompanyPage_component', styles.component)}
      initWizardPage={isWizardInitPage}
    >
      <Box className={styles.box}>
        <form onSubmit={handleSubmit(handleSubmitForm)} className={styles.form}>
          <div className={styles.formContent}>
            <div className={cn(styles.section, styles.sectionCompanyModifier)}>
              <StepTitle number={1} title={i18nt('COMPANY_DETAILS')} className={styles.sectionHeader} />
              <div className={cn(styles.sectionContent, styles.sectionCompany)}>
                <div className={styles.sectionCompanyContent}>
                  <div className={styles.sectionLogo}>
                    <div>{i18nt('LOGO')}:</div>
                    <ImageUpload onUpload={handleUploadLogo} imageUrl={getValues().image} className={styles.logoBox} ref={imageControlRef} />

                    {getValues().image ? (
                      <div className={styles.sectionLogoButtons}>
                        <ButtonDelete variant="text" onClick={() => imageControlRef?.current?.clear()}>
                          {i18nt('LOGO_REMOVE')}
                        </ButtonDelete>
                        {/*<ButtonAdd  onClick={() => imageControlRef?.current?.inputClick()}>*/}
                        {/*  {i18nt('LOGO_UPLOAD')}*/}
                        {/*</ButtonAdd>*/}
                        {/*<ButtonAdd>*/}
                        {/*  Change logo*/}
                        {/*</ButtonAdd>*/}
                      </div>
                    ): (
                      <div className={styles.sectionLogoButtons}>
                        {/*<ButtonDelete>*/}
                        {/*  Remove logo*/}
                        {/*</ButtonDelete>*/}
                        {/*<ButtonAdd  onClick={() => imageControlRef?.current?.inputClick()}>*/}
                        {/*  {i18nt('LOGO_UPLOAD')}*/}
                        {/*</ButtonAdd>*/}
                      </div>
                    )}
                  </div>
                  <div>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Input
                          label={`${i18nt('COMPANY_NAME')} *`}
                          error={!!errors?.name?.message}
                          helperText={errors?.name?.message}
                          value={value}
                          onChange={handleNameChange}
                          className={cn(styles.companyInput)}
                          formMargin
                        />
                      )}
                    />
                  </div>
                  <div>
                    <Controller
                      name="domain"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <div className={styles.domainBox}>
                          <Input
                            label={i18nt('DOMAIN')}
                            error={!!errors?.name?.message}
                            helperText={errors?.name?.message}
                            value={value}
                            onChange={handleDomainChange(onChange)}
                            className={cn(styles.companyInput, styles.domainInput)}
                          />
                          <span>.gil.com.ua</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.section}>
              <StepTitle number={2} title={i18nt('BRANCH_DETAILS')} className={styles.sectionHeader} />
              <div className={styles.sectionContent}>
                <div>
                  <Controller
                    name="organization.name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        label={i18nt('SUBJECT_NAME')}
                        error={!!errors?.organization?.name?.message}
                        helperText={errors?.organization?.name?.message}
                        value={value}
                        onChange={onChange}
                        className={styles.organizationInput}
                        formMargin
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name="organization.address"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        label={i18nt('ADDRESS')}
                        error={!!errors?.organization?.address?.message}
                        helperText={errors?.organization?.address?.message}
                        value={value}
                        onChange={onChange}
                        className={styles.organizationInput}
                        formMargin
                      />
                    )}
                  />
                </div>
                <OrganizationGallerySection
                  items={memoGallery}
                  onChange={handleOrganizationGalleryChange}
                  address={getValues().organization?.address}
                />
                {getValues()?.organization.phones?.map((item, index) => (
                  // const fieldName = `phones[${index}]`;
                  // console.log('getValues()?.phones', getValues()?.phones)
                  // console.log(`phones[${index}]`)

                  <>
                    <Controller
                      // @ts-ignore
                      name={`organization.phones[${index}]`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <PhoneInput
                          error={!!errors?.organization?.address?.message}
                          helperText={errors?.organization?.address?.message}
                          value={value as string}
                          onChange={onChange}
                          className={styles.phoneInput}
                        />
                      )}
                    />
                  </>
                ))}
                <div className={styles.timeRelatedSection}>
                  <div className={styles.workingHoursSection}>
                    <Controller
                      name="organization.workingHoursData.start"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          label={i18nt('FROM')}
                          //@ts-ignore
                          error={!!errors?.workingHoursData?.start?.message}
                          helperText={errors?.organization?.workingHoursData?.start?.message}
                          //@ts-ignore
                          value={value}
                          //@ts-ignore
                          onChange={onChange}
                          className={styles.timeInput}
                          formMargin
                        />
                      )}
                    />
                    <Controller
                      name="organization.workingHoursData.end"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TimePicker
                          label={i18nt('TO')}
                          //@ts-ignore
                          error={!!errors?.workingHoursData?.end?.message}
                          helperText={errors?.organization?.workingHoursData?.end?.message}
                          //@ts-ignore
                          value={value}
                          //@ts-ignore
                          onChange={onChange}
                          className={styles.timeInput}
                          formMargin
                        />
                      )}
                    />
                  </div>
                  <div className={styles.timeZoneAndCurrency}>
                    <div className={styles.timezoneSection}>
                      <Controller
                        name="timezone"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TimezoneAutocomplete onChange={onChange} value={value} className={styles.timezone}
                                                formMargin={false} />
                        )}
                      />
                    </div>
                    <div className={styles.currencySection}>
                      <Controller
                        name="organization.currency"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <SelectMy<{}, string>
                            className={styles.currencies}
                            onChange={option => onChange(option?.value as string)}
                            options={currenciesOptions}
                            error={!!errors?.organization?.currency?.message}
                            helperText={errors?.organization?.currency?.message}
                            value={value}
                            label={i18nt('CURRENCY')}
                            isPrimitiveValue
                            disableClearable
                            formMargin
                          />
                        )}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button type="submit" variant="primary" disabled={!isValidCustom}>
              {isWizardInitPage ? i18nt('START_USING') : i18nt('ADD_COMPANY')}
            </Button>
          </div>
        </form>
      </Box>
    </LayoutContent>
  );
};

export default CreateNewCompanyPage;
