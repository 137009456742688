import api from 'api/api';
import { OrderDTO, OrderLabel } from 'components/types';

import { ApiConfig, ApiResponse } from './apiTypes';

export const MOCKED_EVENTS_DB = [
  {
    id: 10,
    client_id: 12,
    organization_id: 5,
    employee_id: 1,
    services_ids: [1],
    date: '2022-06-08',
    time_start: '2022-06-08:11:00',
    time_end: '2022-06-08:12:00',
    duration: 60,
    updated_at: '2022-06-05T14:14:35.000000Z',
    created_at: '2022-06-05T14:14:35.000000Z',
    service: [
      {
        id: 1,
        organization_id: 5,
        name: '\u0413\u043e\u043b\u0456\u043d\u043d\u044f',
        updated_at: '2022-05-21T14:36:23.000000Z',
        created_at: '2022-05-21T14:36:23.000000Z',
        items: [
          {
            id: 1,
            service_id: 1,
            name:
              '\u0413\u043e\u043b\u0456\u043d\u043d\u044f \u0431\u043e\u0440\u043e\u0434\u0438',
            image: null,
            price: 10,
            currency: 'UAH',
            duration: 30,
            updated_at: '2022-05-28T16:17:25.000000Z',
            created_at: '2022-05-28T16:17:26.000000Z',
            service: {
              id: 1,
              organization_id: 5,
              name: '\u0413\u043e\u043b\u0456\u043d\u043d\u044f',
              updated_at: '2022-05-21T14:36:23.000000Z',
              created_at: '2022-05-21T14:36:23.000000Z',
            },
          },
        ],
      },
    ],
    contactDetails: {
      id: 12,
      name: '1',
      email: '2',
      phone: '3',
      comment: null,
      read_docs: true,
      reminder_value: 120,
      updated_at: '2022-06-05T14:14:35.000000Z',
      created_at: '2022-06-05T14:14:35.000000Z',
    },
    organization: {
      id: 5,
      user_id: 2,
      name: '\u0411\u043e\u0440\u043e\u0434\u043012',
      address:
        '\u0421\u043f\u0435\u0446\u0456\u0430\u043b\u0456\u0441\u0442\u0456\u0432 5',
      phones: ['+380445555555'],
      working_time: '8:00-20:00',
      updated_at: '2022-06-04T17:09:59.000000Z',
      created_at: '2022-05-23T19:38:24.000000Z',
    },
    employee: {
      id: 1,
      organization_id: 5,
      name: '\u042e\u0440\u0430',
      first_name: null,
      last_name: null,
      middle_name: null,
      languages: ['en'],
      rating: 5,
      reviewCount: 5,
      employment_date: null,
      position_id: null,
      nearestTimeslots: ['2022-05-23 20:23:51'],
      description: null,
      updated_at: '2022-05-23T20:23:51.000000Z',
      created_at: '2022-05-23T20:23:51.000000Z',
    },
    client: {
      id: 12,
      name: '1',
      email: '2',
      phone: '3',
      comment: null,
      read_docs: true,
      reminder_value: 120,
      updated_at: '2022-06-05T14:14:35.000000Z',
      created_at: '2022-06-05T14:14:35.000000Z',
    },
  },
  {
    id: 11,
    client_id: 12,
    organization_id: 5,
    employee_id: 1,
    services_ids: [1],
    date: '2022-06-08',
    time_start: '2022-06-08:14:00',
    time_end: '2022-06-08:15:00',
    duration: 60,
    updated_at: '2022-06-05T14:14:35.000000Z',
    created_at: '2022-06-05T14:14:35.000000Z',
    service: [
      {
        id: 1,
        organization_id: 5,
        name: '\u0413\u043e\u043b\u0456\u043d\u043d\u044f',
        updated_at: '2022-05-21T14:36:23.000000Z',
        created_at: '2022-05-21T14:36:23.000000Z',
        items: [
          {
            id: 1,
            service_id: 1,
            name:
              '\u0413\u043e\u043b\u0456\u043d\u043d\u044f \u0431\u043e\u0440\u043e\u0434\u0438',
            image: null,
            price: 10,
            currency: 'UAH',
            duration: 30,
            updated_at: '2022-05-28T16:17:25.000000Z',
            created_at: '2022-05-28T16:17:26.000000Z',
            service: {
              id: 1,
              organization_id: 5,
              name: '\u0413\u043e\u043b\u0456\u043d\u043d\u044f',
              updated_at: '2022-05-21T14:36:23.000000Z',
              created_at: '2022-05-21T14:36:23.000000Z',
            },
          },
        ],
      },
    ],
    contactDetails: {
      id: 12,
      name: '1',
      email: '2',
      phone: '3',
      comment: null,
      read_docs: true,
      reminder_value: 120,
      updated_at: '2022-06-05T14:14:35.000000Z',
      created_at: '2022-06-05T14:14:35.000000Z',
    },
    organization: {
      id: 5,
      user_id: 2,
      name: '\u0411\u043e\u0440\u043e\u0434\u043012',
      address:
        '\u0421\u043f\u0435\u0446\u0456\u0430\u043b\u0456\u0441\u0442\u0456\u0432 5',
      phones: ['+380445555555'],
      working_time: '8:00-20:00',
      updated_at: '2022-06-04T17:09:59.000000Z',
      created_at: '2022-05-23T19:38:24.000000Z',
    },
    employee: {
      id: 1,
      organization_id: 5,
      name: '\u042e\u0440\u0430',
      first_name: null,
      last_name: null,
      middle_name: null,
      languages: ['en'],
      rating: 5,
      reviewCount: 5,
      employment_date: null,
      position_id: null,
      nearestTimeslots: ['2022-05-23 20:23:51'],
      description: null,
      updated_at: '2022-05-23T20:23:51.000000Z',
      created_at: '2022-05-23T20:23:51.000000Z',
    },
    client: {
      id: 12,
      name: '1',
      email: '2',
      phone: '3',
      comment: null,
      read_docs: true,
      reminder_value: 120,
      updated_at: '2022-06-05T14:14:35.000000Z',
      created_at: '2022-06-05T14:14:35.000000Z',
    },
  },
];

//@ts-ignore
// export const apiGetOrders = (organizationId?: number): Promise<ApiResponse<OrderDTO[]>> => fakeRequest({data: MOCKED_EVENTS_DB}).then(r => toCamelCase(r))

// export const apiGetOrders = (organizationId?: number): Promise<ApiResponse<OrderDTO[]>> => fakeRequest({data: MOCKED_EVENTS_DB}).then(r => toCamelCase(r))
export const apiGetOrders = (
  organizationId: number,
  query?: any,
): Promise<ApiResponse<OrderDTO[]>> =>
  api.get(`/administration/organization/${organizationId}/orders`, query);

export const apiPostOrders = (
  organizationId: number,
  order: any,
  query?: string,
  config?: ApiConfig,
): Promise<ApiResponse<OrderDTO>> =>
  api.post(`/administration/organization/${organizationId}/orders${query}`, order, config);

export const apiPatchOrder = (
  organizationId: number,
  order: any,
  orderId: string,
  query?: string,
  config?: ApiConfig,
): Promise<ApiResponse<OrderDTO>> =>
  api.patch(`/administration/organization/${organizationId}/orders/${orderId}${query}`, order, config);

export const apiOrderCancel = (
  organizationId: number,
  orderId: number,
  reason?: string,
): Promise<ApiResponse<OrderDTO>> =>
  api.delete(`/administration/organization/${organizationId}/orders/${orderId}?reason=${reason}`);

export const apiGetOrderLabels = (
  companyId: number,
  organizationId: number,
): Promise<ApiResponse<OrderLabel[]>> =>
  api.get(`/administration/company/${companyId}/organization/${organizationId}/labels`);

export const apiPostOrderLabel = (
  companyId: number,
  organizationId: number,
  label: OrderLabel,
): Promise<ApiResponse<OrderLabel>> =>
  api.post(`/administration/company/${companyId}/organization/${organizationId}/labels`, label);

export const apiDeleteOrderLabel = (
  companyId: number,
  organizationId: number,
  labelId: number,
): Promise<ApiResponse<OrderLabel>> =>
  api.delete(`/administration/company/${companyId}/organization/${organizationId}/labels/${labelId}`);
