import React from 'react';

import cn from 'classnames';

import Button, { ButtonProps } from 'components/Buttons/Button';
import { i18nt } from 'translations/i18n';

import styles from './ButtonDelete.module.scss';

const ButtonDelete = ({variant = 'primary', children, startIcon = 'trash', className, ...props}: ButtonProps) => (
  <Button
    variant={variant}
    startIcon={startIcon}
    className={cn('ButtonDelete_component', styles.component,
      variant === 'primary' && styles.primary,
      // variant === 'secondary' && styles.secondary,
      variant === 'text' && styles.text,
      className
    )}
    {...props}
  >
    {children || i18nt('DELETE')}
  </Button>
);

export default ButtonDelete;
